.flexible-item-call_to_action {
  background: #fff;
  border-top: solid 1px #dadada;
  border-bottom: solid 1px #dadada;
  margin-top: 0;
}

.call-to-action {
  padding-top: 15px;
  padding-bottom: 15px;

  @media(min-width: $screen-sm-min) {
    padding-top: 30px;
    padding-bottom: 30px;

    .button-wrapper {
      text-align: right;
    }
  }

  .title {
    font-size: 25px;
    color: #3F4446;
    position: relative;
    top: -1px;

    @media(min-width: $screen-sm-min) {
      font-size: 29px;
      @include text-style('gilroy');
    }
  }
}