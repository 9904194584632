.spacer {
  height: 1px;
  margin: 10px 0;
  background: #18ce97;
  background: -moz-linear-gradient(45deg, #18ce97 0%, #53c419 100%);
  background: -webkit-linear-gradient(45deg, #18ce97 0%,#53c419 100%);
  background: linear-gradient(45deg, #18ce97 0%,#53c419 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18ce97', endColorstr='#53c419',GradientType=1 );
  display: inline-block;
  width: 100%;

  &.spacer-xs {
    width: 30px;
  }
}