header.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding: 12px 0;

  .mobile-menu-toggle {
    font-size: 32px;
    color: #fff;
    float: right;
    padding-top: 3px;

    @media(min-width: $screen-lg-min) {
      display: none;
    }

    .menu-title-text {
      font-size: 12px;
      margin-top: -10px;
      text-align: middle;
    }
  }

  @media(min-width: $screen-lg-min) {
    .top-menu-search-classer {
      background: url(../images/search-icon-white.png) center center no-repeat;
      background-size: 15px 15px;

      > a {
        color: transparent !important;
        text-shadow: none !important;
      }
    }
  }

  .nav-primary {
    background: #fff;
    margin-top: 10px;
    border-radius: 6px;
    display: none;
    width: 100% !important;
    box-shadow: 0 8px 2px -2px rgba(0, 0, 0, 0.3);

    @media(min-width: $screen-lg-min) {
      float: right;
      display: block;
      background: transparent;
      margin-top: 0;
      width: auto !important;
      box-shadow: none;
    }

    .nav {
      > .menu-item {
        position: relative;
      }

      .menu-item-has-children {
        .sub-menu {
          display: none;
        }

        &:hover {
          .sub-menu {
            display: block;
          }
        }
      }

      .menu-item {
        @media(min-width: $screen-lg-min) {
          display: inline-block;

          a {
            border: solid 1px transparent;
          }

          &:hover {
            .sub-menu {
              opacity: 1;
              z-index: 1;
            }
          }

          &.banner-item a {
            border-color: #fff;
            border-radius: 8px;
            padding: 7px 10px;
          }
        }

        a {
          font-size: 16px;
          color: #0b0b0b;
          font-weight: 200;

          @media(min-width: $screen-lg-min) {
            color: #fff;
            transition: text-shadow 0.2s linear;

            &:focus,
            &:hover {
              text-shadow: 1px 2px 3px #666;
              background: transparent;
              color: #fff;
            }
          }
        }

        @media(min-width: $screen-lg-min) {
          &:nth-child(9),
          &:nth-child(8),
          &:nth-child(7),
          &:nth-child(6),
          &:nth-child(5),
          &:nth-child(4) {
            .sub-menu {
              left: auto;
            }
          }
        }
      }

      .sub-menu {
        padding: 0 30px;
        background: #fff;
        border-radius: 6px;
        list-style: none;

        @media(min-width: $screen-lg-min) {
          padding: 4px 15px;
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          transition: opacity 0.1s linear;
          opacity: 0;
          z-index: -1;
          width: 250px;
        }

        .menu-item {
          display: block;
        }

        a {
          color: #0b0b0b;
          padding: 10px 15px;
          display: block;

          @media(min-width: $screen-md-min) {
            padding: 3px 0;
          }

          &:focus,
          &:hover {
            color: #0b0b0b;
            text-decoration: none;
          }
        }
      }
    }
  }

  &.banner-dark {
    @media(min-width: $screen-lg-min) {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    @media(min-width: $screen-lg-min) {
      .top-menu-search-classer {
        background-image: url(../images/search-icon.png);
      }
    }

    .mobile-menu-toggle {
      color: #243128;
    }

    .nav {
      .menu-item {
        a {
          @media(min-width: $screen-lg-min) {
            color: #243128;

            &:focus,
            &:hover {
              color: #243128;
              text-shadow: 1px 2px 3px #aaaaaa;
            }
          }
        }

        &.banner-item a {
          border-color: #53C419;
          border-radius: 0;
        }
      }
    }
  }

  .bop-nav-search {
    .search-submit {
      background: url(../images/search-icon.png) center center no-repeat;
      background-size: 15px 15px;
      border: 0;
      color: transparent;
      width: 24px;
    }

    .search-field {
      border-radius: 4px;
      font-weight: normal;
      border: solid 1px #ddd;
      padding: 3px;
    }
  }
}

.breadcrumbs-wrapper {
  display: none;
}

.page-template-default .breadcrumbs-wrapper {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  border-bottom: solid 1px #bababa;
}