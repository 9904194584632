.btn-globalf-1 {
  background: #18ce97;
  background: -moz-linear-gradient(45deg, #18ce97 0%, #53c419 100%);
  background: -webkit-linear-gradient(45deg, #18ce97 0%,#53c419 100%);
  background: linear-gradient(45deg, #18ce97 0%,#53c419 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18ce97', endColorstr='#53c419',GradientType=1 );
  color: #fff;
  transition: text-shadow 0.2s linear;
  @include text-style('gilroy');

  &.btn-lg {
    font-size: 16px;
  }

  &:hover {
    color: #fff;
    text-shadow: 1px 2px 3px #666;
  }

  a,
  p {
    text-decoration: none;
    margin: 0;
    color: #fff;
  }
}

.btn-globalf-2 {
  background: #243128;
  color: #fff;
  transition: background 0.2s linear;
  @include text-style('gilroy');

  &.btn-lg {
    font-size: 16px;
  }

  &:hover {
    color: #fff;
    background: #2d3c32;
  }


  a,
  p {
    text-decoration: none;
    margin: 0;
    color: #fff;
  }
}