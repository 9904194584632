.link-grid {
  background-image: linear-gradient(0deg, #FFFFFF 1%, #E8EFEA 99%);
  padding: 20px 0;

  @media(min-width: $screen-md-min) {
    padding: 40px 0;
  }

  @media(min-width: $screen-lg-min) {
    padding: 110px 0;
  }

  .title {
    /* Bilforsikring kan in: */
    color: #393939;
    font-size: 25px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 32px;
      letter-spacing: -1.38px;
      line-height: 1.2;
    }

    @media(min-width: $screen-md-min) {
      font-size: 53px;
    }
  }

  .link-grid-items {
    @media(min-width: $screen-sm-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
    }

    .grid-item {
      background: #FFFFFF;
      box-shadow: 0 0 23px 0 rgba(76, 77, 78, 0.09);
      border-radius: 5px;
      margin: 15px;
      position: relative;
      padding: 40px 15px 20px 15px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      text-align: center;
      align-items: center;

      @media(min-width: $screen-sm-min) {
        flex: 1 0 47%;
        min-height: 260px;
        padding: 40px 15px;
      }

      @media(min-width: $screen-sm-min) {
        flex: 1 0 30%;
      }

      .grid-info-field {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 13px;
        padding: 3px 6px;
        letter-spacing: 0;
        border-radius: 2px;

        &.grid-info-field-grey {
          background: #A7ABAC;
        }

        &.grid-info-field-hidden {
          display: none !important;
        }
      }

      .grid-image {
        flex-grow: 1;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 67px;
          width: auto;
        }
      }

      .grid-title {
        @include text-style('noval');
        color: #243128;
        line-height: 1.2;
        font-size: 26px;

        @media(min-width: $screen-sm-min) {
          font-size: 32px;
        }
      }

      .grid-link {
        color: #000;
      }
    }
  }
}