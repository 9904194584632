.simplePageLoad {
  -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.6s; /* Firefox < 16 */
  -ms-animation: fadein 0.6s; /* Internet Explorer */
  -o-animation: fadein 0.6s; /* Opera < 12.1 */
  animation: fadein 0.6s;
}

.simplePageLoadFromTop {
  -webkit-animation: fadeinFromTop 0.6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFromTop 0.6s; /* Firefox < 16 */
  -ms-animation: fadeinFromTop 0.6s; /* Internet Explorer */
  -o-animation: fadeinFromTop 0.6s; /* Opera < 12.1 */
  animation: fadeinFromTop 0.6s;
}

.simplePageLoadFromRight {
  -webkit-animation: fadeinFromRight 0.6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFromRight 0.6s; /* Firefox < 16 */
  -ms-animation: fadeinFromRight 0.6s; /* Internet Explorer */
  -o-animation: fadeinFromRight 0.6s; /* Opera < 12.1 */
  animation: fadeinFromRight 0.6s;
}

@keyframes fadein {
  from { opacity: 0.001; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0.001; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0.001; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0.001; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0.001; }
  to   { opacity: 1; }
}

@keyframes fadeinFromTop {
  from { opacity: 0.001; transform: translateY(-40px); }
  to   { opacity: 1; transform: translateY(0px); }
}

/* Firefox < 16 */
@-moz-keyframes fadeinFromTop {
  from { opacity: 0.001; transform: translateY(-40px); }
  to   { opacity: 1; transform: translateY(0px); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinFromTop {
  from { opacity: 0.001; transform: translateY(-40px); }
  to   { opacity: 1; transform: translateY(0px); }
}

/* Internet Explorer */
@-ms-keyframes fadeinFromTop {
  from { opacity: 0.001; transform: translateY(-40px); }
  to   { opacity: 1; transform: translateY(0px); }
}

/* Opera < 12.1 */
@-o-keyframes fadeinFromTop {
  from { opacity: 0.001; transform: translateY(-40px); }
  to   { opacity: 1; transform: translateY(0px); }
}

@keyframes fadeinFromRight {
  from { opacity: 0.001; transform: translateX(40px); }
  to   { opacity: 1; transform: translateX(0px); }
}

/* Firefox < 16 */
@-moz-keyframes fadeinFromRight {
  from { opacity: 0.001; transform: translateX(40px); }
  to   { opacity: 1; transform: translateX(0px); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinFromRight {
  from { opacity: 0.001; transform: translateX(40px); }
  to   { opacity: 1; transform: translateX(0px); }
}

/* Internet Explorer */
@-ms-keyframes fadeinFromRight {
  from { opacity: 0.001; transform: translateX(40px); }
  to   { opacity: 1; transform: translateX(0px); }
}

/* Opera < 12.1 */
@-o-keyframes fadeinFromRight {
  from { opacity: 0.001; transform: translateX(40px); }
  to   { opacity: 1; transform: translateX(0px); }
}