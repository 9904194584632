@mixin text-style($item) {
  @if $item == 'gilroy' {
    font-family: 'Gilroy';
    font-weight: 800;
    font-style: normal;
  }

  @if $item == 'gooddog' {
    font-family: 'GoodDog';
    font-weight: normal;
    font-style: normal;
  }

  @if $item == 'noval' {
    font-family: 'Proxima Nova Lt';
    font-weight: 300;
    font-style: normal;
  }

  @if $item == 'novali' {
    font-family: 'Proxima Nova Lt';
    font-weight: 300;
    font-style: italic;
  }

  @if $item == 'novar' {
    font-family: 'Proxima Nova Rg';
    font-weight: normal;
    font-style: normal;
  }

  @if $item == 'novari' {
    font-family: 'Proxima Nova Rg';
    font-weight: normal;
    font-style: italic;
  }
}