.info-block-page {
  padding-bottom: 40px;
  position: relative;
  z-index: 2;

  @media(min-width: $screen-md-min) {
    padding-bottom: 60px;
  }

  .info-blocks-wrap {
    @media(min-width: $screen-md-min) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }

  .info-box-left {
    @include make-xs-column(12);
    @include make-md-column(7);
    @include make-lg-column(7.5);
  }

  .info-box-right {
    @include make-xs-column(12);
    @include make-md-column(5);
    @include make-lg-column(4.5);
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h1,
  .h1,
  .h2,
  h2 {
    @include text-style('gilroy');
    color: #243128;
  }

  .info-box {
    background: #fff;
    box-shadow: 0 0 43px 0 rgba(0,0,0,0.09);

    @media(min-width: $screen-md-min) {
      margin-top: -50px;
      padding: 20px 30px 70px 30px;
    }

    @media(min-width: $screen-lg-min) {
      padding: 30px 60px 70px 60px;
    }

    @media(min-width: 1600px) {
      margin-top: -80px;
    }

    &.info-box-right {
      background-image: linear-gradient(-224deg, #243128 0%, #3A4B3F 100%);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 0;
      color: #fff;

      .text-wrapper {
        flex-grow: 1;
        padding: 15px;
        font-size: 16px;
        @include text-style('noval');

        @media(min-width: $screen-md-min) {
          padding: 30px;
          font-size: 19px;
          line-height: 1.5;
        }

        @media(min-width: $screen-lg-min) {
          padding: 30px 60px 30px 60px;
        }
      }


      h3,
      .h3,
      h4,
      .h4,
      h5,
      .h5,
      h1,
      .h1,
      .h2,
      h2 {
        color: #fff;
      }
    }

    &.info-box-left {
      @include text-style('noval');
      font-size: 15px;
      color: #737882;

      .btn {
        @media(min-width: $screen-md-min) {
          width: 300px;
          max-width: 100%;
        }
      }
    }
  }

  .box-person {
    .image {
      display: inline-block;
      width: 70%;
      vertical-align: bottom;
    }

    .person-info {
      display: inline-block;
      width: 29%;
      padding: 10px 10px 30px 10px;

      .name {
        @include text-style('novar');
        font-size: 16px;
        letter-spacing: -0.36px;
        margin-bottom: 3px;
      }

      .position {
        @include text-style('novar');
        font-size: 11px;
      }

      .signature {
        margin-top: 20px;
        max-width: 92px;
      }
    }
  }
}