body {
  @include text-style('novar');
  background: #f6f6f6;
}

a {
  color: #53C419;
  outline: 0;

  &:focus,
  &:hover {
    color: #49ac16;
    outline: 0;
  }
}

.globalf-block {
  background-image: linear-gradient(-224deg, #18CE97 0%, #8CD126 100%);
  color: #fff;
  font-weight: bold;
}

.checkmark-list ul {
  padding-left: 27px;
  list-style-position: outside;
  list-style-image: url(../images/checkmark.png);
}

.arrow-link {
  @include text-style('novar');
  color: #243128;

  &:hover {
    color: #243128;
  }

  &::before {
    content: "\e092";
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    color: #53C419;
    vertical-align: top;
    padding: 1px 4px 0 0;
  }
}

.gf-small-text {
  font-size: 15px;
}

.shopping-iframe {
  border: 0;
  height: 1000px;
  width: 100%;
  margin-top: 40px;
}