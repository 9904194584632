.qa-element {
  background: #F6F6F6;
  padding: 30px 0;

  @media(min-width: $screen-md-min) {
    padding: 50px 0;
  }

  .title {
    color: #393939;
    font-size: 20px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 54px;
      letter-spacing: -1.38px;
      margin-bottom: 30px;
    }
  }

  .panel {
    border: 0;

    .panel-heading {
      background: #fff;
      @include text-style('noval');
      color: #243128;
    }

    .panel-title {
      @media(min-width: $screen-sm-min) {
        font-size: 20px;
      }

      @media(min-width: $screen-md-min) {
        font-size: 25px;
      }

      h4,
      .h4 {
        margin: 0;
      }

      a {
        position: relative;
        display: block;
        color: #243128;

        &::after {
          content: "\e259";
          font-family: "Glyphicons Halflings";
          line-height: 1;
          font-size: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 10px;

          @media(min-width: $screen-md-min) {
            font-size: 14px;
            height: 14px;
          }
        }

        &.collapsed {
          &::after {
            content: "\e258";
          }
        }

        &:focus,
        &:hover {
          color: #243128;
          text-decoration: none;
        }
      }
    }

  }
}