footer.content-info {
  background: #F6F6F6;
  padding-top: 30px;
  padding-bottom: 15px;

  @media(min-width: $screen-sm-min) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  @media(min-width: $screen-md-min) {
    padding-top: 95px;
  }

  .widget_nav_menu {
    ul {
      list-style: none;
      padding: 0;
    }

    .menu {
      > li {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        margin-bottom: 30px;

        @media(min-width: $screen-sm-min) {
          width: 49%;
        }

        @media(min-width: $screen-md-min) {
          width: 24.5%;
        }

        > a {
          color: #3F4446;
          font-size: 18px;
          @include text-style('gilroy');
        }
      }
    }

    .sub-menu {
      > li {
        > a {
          font-size: 14px;
          color: #737882;
          padding: 3px 0;
          display: block;
          font-weight: 200;
        }
      }
    }
  }

  .share-title {
    color: #3F4446;
    font-size: 18px;
    margin-bottom: 10px;
    @include text-style('gilroy');
  }

  .share-items {
    a {
      display: inline-block;
      margin: 5px;
    }
  }

  .share-container {
    margin-top: 10px;
  }
}