.usps-list {
  margin-top: 30px;
  margin-bottom: 30px;

  @media(min-width: $screen-md-min) {
    margin-top: 60px;
    margin-bottom: 10px;
  }

  .list-item {
    position: relative;
    text-align: left;
    display: block;
    background: url(../images/checkmark-circle.png) left center no-repeat;
    background-size: 40px;
    height: 40px;
    padding-left: 50px;
    @include text-style('noval');
    margin-bottom: 25px;
    color: #393939;

    @media(min-width: $screen-sm-min) {
      background-size: 60px;
      height: 60px;
      padding-left: 70px;
      display: inline-block;
      width: 32%;
      margin-right: 1%;
      font-size: 18px;
      overflow: hidden;
    }

    @media(min-width: $screen-md-min) {
      background-size: 74px;
      height: 74px;
      padding-left: 82px;
      font-size: 25px;
    }

    .text-aligned {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .container {
    max-width: 1170px;
  }
}