.custom-text-element {
  padding: 20px 15px;

  img {
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    @include text-style('gilroy');
  }
}

.flexible-item-seo_element,
.flexible-item-custom_text_element {
  background: #fff;
}