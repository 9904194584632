.landing-page-content {
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E8EFEA 100%);
  position: relative;
  z-index: 2;
  padding-bottom: 40px;

  .container {
    @media(min-width: 1400px) {
      width: 1220px;
    }
  }

  .landing-boxes {
    @media(min-width: $screen-md-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .landing-box {
    background: #fff;
    box-shadow: 0 0 23px 0 rgba(76, 77, 78, 0.35);
    margin-bottom: 20px;
    padding: 15px;
    font-size: 15px;
    @include text-style('noval');

    @media(min-width: $screen-md-min) {
      display: inline-block;
      padding: 25px 25px 55px 25px;
    }

    .h2,
    h2 {
      color: #243128;
      font-size: 26px;
      letter-spacing: -0.5px;
      @include text-style('gilroy');
      margin: 0 0 7px 0;

      @media(min-width: $screen-md-min) {
        font-size: 38px;
        letter-spacing: -0.97px;
      }
    }

    .lead {
      color: #737882;
    }

    &.landing-box-left {
      @media(min-width: $screen-md-min) {
        width: 47%;
        margin: -50px 2% 0 0;
      }

      @media(min-width: 1600px) {
        width: 50%;
        margin: -80px 1% 0 0;
      }
    }

    &.landing-box-right {
      background-image: linear-gradient(180deg, #18CE97 0%, #8CD126 100%);
      color: #fff;
      @include text-style('noval');
      font-size: 18px;
      line-height: 1.2;

      @media(min-width: $screen-md-min) {
        width: 50%;
        margin: -50px 0 0 2%;
        font-size: 31px;
        padding-top: 50px;
      }

      @media(min-width: 1600px) {
        width: 49%;
        margin: -80px 0 0 1%;
        padding-top: 80px;
      }
    }

    .landing-icon {
      margin-bottom: 8px;
    }
  }

  .landing-car {
    max-width: 986px;

    @media(min-width: $screen-md-min) {
      margin-top: -30px;
    }
  }
}