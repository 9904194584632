// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.nf-form-cont {
  @include text-style('noval');
  font-size: 15px;
  color: #737882;

  .small-checkbox-wrapper {
    display: inline-block;
    width: 100%;

    @media(min-width: $screen-md-min) {
      width: auto;
      margin-right: 15px;
    }

    .checkbox-wrap {
      position: relative;

      @media(min-width: $screen-md-min) {
        .nf-field-label {
          padding-left: 24px;
        }

        .nf-field-element {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .nf-field-label {
    label {
      font-weight: inherit;
    }
  }

  .textarea-container textarea,
  .phone-container input,
  .email-container input,
  .textbox-container input {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    padding: 7px 9px;
  }

  .submit-container input {
    @include text-style('gilroy');
    background-image: linear-gradient(-220deg, #18CE97 0%, #53C419 100%);
    border-radius: 2px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.5px;
    border: 0;
    display: block;
    padding: 7px;
    width: 100% !important;
  }

  .nf-form-content {
    .h3 {
      @include text-style('noval');
      font-size: 18px;
      color: #393939;
      letter-spacing: -0.59px;
      margin: 0 0 7px 0;

      @media(min-width: $screen-md-min) {
        font-size: 23px;
      }
    }

    .form-bold {
      @include text-style('gilroy');
    }
  }

  .html-container {
    margin: 0;
  }

  .form-phone-html,
  .form-email-html {
    background: url(../images/icon-conversation.png) center left no-repeat;
    background-size: 20px auto;
    @include text-style('noval');
    color: #243128;
    letter-spacing: 0;
    line-height: 1.8;
    padding-left: 30px;
    margin: 7px 0;

    @media(min-width: $screen-md-min) {
      font-size: 22px;
      background-size: 38px auto;
      padding-left: 48px;
      margin: 13px 0;
    }

    a {
      color: #243128;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .form-email-html {
    background-image: url(../images/icon-email.png);
  }

  .nf-form-title {
    @include text-style('gilroy');
    font-size: 20px;
    line-height: 1.1;
    color: #243128;

    @media(min-width: $screen-md-min) {
      letter-spacing: -0.5px;
      font-size: 26px;
    }

    @media(min-width: $screen-lg-min) {
      letter-spacing: -0.97px;
      font-size: 34px;
    }
  }

  .nf-form-fields-required {
    font-size: 13px;
    margin-bottom: 8px;
    color: #737882;
  }

  .form-small-text {
    font-size: 13px;
    color: #737882;
    letter-spacing: 0;
  }

  .form-movie-card,
  .form-gift-card {
    background: #FFFFFF;
    box-shadow: 0 0 18px 0 rgba(76, 77, 78, 0.09);
    border-radius: 3.64px;
    border: solid 1px transparent;
    transition: all 0.3s linear;
    padding: 5px;

    &:hover {
      border: 1px solid #DCDCDC;
      box-shadow: 0 0 18px 0 rgba(76, 77, 78, 0.09);
    }

    .nf-field-element {
      width: 80px !important;

      &::after {
        content: " ";
        display: inline-block;
        background: url(../images/gave-clip.png) center center no-repeat;
        background-size: 53px 41px;
        width: 53px;
        height: 41px;
        vertical-align: middle;
        position: relative;
        margin-left: 6px;
        top: 15px;
      }

      input {
        width: auto !important;
        position: relative;
        top: 15px;
      }
    }

    .nf-field-label {
      @include text-style('gilroy');
      font-size: 20px;
      color: #393939;
      letter-spacing: -0.51px;
      line-height: 25px;
    }

    .nf-field-description {
      @include text-style('noval');
      font-size: 15px;
      color: #737882;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 0 88px;
    }
  }

  .form-movie-card {
    .nf-field-element {
      width: 80px !important;

      &::after {
        background: url(../images/film-clip.png) center center no-repeat;
        background-size: 38px 43px;
        width: 38px;
        height: 43px;
        margin-left: 12px;
      }
    }
  }
}