.steps {
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E8EFEA 100%);
  padding-top: 15px;
  padding-bottom: 15px;

  @media(min-width: $screen-sm-min) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media(min-width: $screen-md-min) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .title {
    color: #393939;
    font-size: 25px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 54px;
    }
  }

  .step-items {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    @media(min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  .step-item {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media(min-width: $screen-sm-min) {
      background: url(../images/arrow-right.png) 97% 20% no-repeat;
      background-size: 28px auto;
      width: 25%;
      padding-right: 40px;
      margin-bottom: 50px;
    }

    @media(min-width: $screen-md-min) {
      padding-right: 50px;
    }

    .step-title {
      color: #393939;
      font-size: 18px;
      flex-grow: 1;
      @include text-style('gilroy');

      @media(min-width: $screen-sm-min) {
        font-size: 21px;
      }
    }

    .step-image {
      margin-bottom: 10px;

      @media(min-width: $screen-sm-min) {
        margin-bottom: 30px;
      }
    }

    .step-text {
      margin-top: 10px;
      color: #737882;
      font-size: 15px;
      font-weight: 200;
      line-height: 1.7;
    }

    &:last-child {
      background: transparent;
    }
  }
}