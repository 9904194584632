.referral-page-content {
  position: relative;
  z-index: 2;

  .referral-form-wrap {
    @media(min-width: $screen-sm-min) {
      margin-top: -400px;
    }
  }

  .referral-form {
    background: #fff;
    box-shadow: 0 0 43px 0 rgba(0,0,0,0.09);
    padding: 15px;

    @media(min-width: $screen-md-min) {
      padding: 20px 30px 70px 30px;
    }

    @media(min-width: $screen-lg-min) {
      padding: 30px 60px 70px 60px;
    }
  }

  .form-text-image {
    margin-bottom: 7px;
  }

  .referral-text {
    color: #737882;
    margin-top: 20px;

    @media(min-width: $screen-md-min) {
      font-size: 20px;
      margin-top: 40px;
    }

    @media(min-width: $screen-lg-min) {
      padding-right: 70px;
    }
  }


  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h1,
  .h1,
  .h2,
  h2 {
    color: #243128;
    @include text-style('gilroy');
  }
}

.page-template-template-referral {
  .hero-element {
    .title,
    .text {
      @media(min-width: $screen-lg-min) {
        padding-right: 70px;
      }
    }
  }
}