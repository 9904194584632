.trustpilot {
  background-image: linear-gradient(0deg, #FFFFFF 1%, #E8EFEA 99%);
  padding-top: 15px;
  padding-bottom: 15px;

  @media(min-width: $screen-sm-min) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media(min-width: $screen-md-min) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .title {
    color: #393939;
    font-size: 20px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 54px;
    }
  }
}