.hero-element {
  background: url(../images/green-default-bg.png) center center no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  padding-bottom: 20px;
  padding-top: 80px;

  @media(min-width: $screen-sm-min) {
    min-height: 400px;
    padding-bottom: 30px;
    padding-top: 140px;
  }

  @media(min-width: 1600px) {
    min-height: 700px;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 50px;
      line-height: 1.3;
    }

    @media(min-width: $screen-lg-min) {
      font-size: 70px;
      line-height: 0.95;
    }
  }

  .text {
    color: #fff;
    font-size: 18px;
    font-weight: 200;

    @media(min-width: $screen-sm-min) {
      font-size: 24px;
    }
  }

  .quick-submit-form {
    padding-top: 40px;
    background: url(../images/numberplate-input-198kk.png) 10% top no-repeat;
    background-size: 196px auto;
    margin-top: 20px;

    @media(min-width: $screen-sm-min) {
      margin-top: 40px;
    }

    .input-field {
      background: #fff;
      height: 43px;
      padding: 5.5px 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      border: solid 1px transparent;

      &.error {
        border-color: red;
      }
    }

    .lang-type {
      background: #243128;
      display: inline-block;
      font-size: 15px;
      color: #fff;
      font-weight: 100;
      padding: 5px 7px;
      border-radius: 4px;
      vertical-align: middle;
    }

    input {
      flex-grow: 1;
      border: 0;
      padding: 0 10px;
    }

    .glyphicon {
      font-size: 20px;
      display: inline-block;
      vertical-align: middle;
      top: -2px;
    }
  }

  .header-block-image {
    @media(min-width: $screen-md-min) {
      position: absolute;
      top: 20%;
      right: 0;
      z-index: 1;
      max-width: 53%;
    }
  }

  .hero-video {
    a {
      display: block;
      padding-left: 35px;
      background: url(../images/play-icon.png) left center no-repeat;
      background-size: 24px auto;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 24px;
      font-weight: 200;
      line-height: 24px;

      @media(min-width: $screen-sm-min) {
        margin-top: 30px;
      }

      @media(min-width: $screen-md-min) {
        margin-top: 40px;
      }
    }
  }

  &.hero-element-default {
    text-align: center;
    min-height: 0;
    padding-bottom: 40px;
    padding-top: 40px;
    margin-top: 70px;

    @media(min-width: $screen-sm-min) {
      padding-bottom: 60px;
    }

    @media(min-width: 1600px) {
      min-height: 447px;
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 24px;
      letter-spacing: 1px;

      @media(min-width: $screen-sm-min) {
        font-size: 30px;
        margin-bottom: 20px;
      }

      @media(min-width: $screen-md-min) {
        font-size: 50px;
        line-height: 1.3;
      }

      @media(min-width: $screen-lg-min) {
        line-height: 0.95;
        font-size: 90px;
        letter-spacing: -2.57px;
      }
    }

    .text {
      font-size: 16px;
      @include text-style('noval');

      @media(min-width: $screen-sm-min) {
        font-size: 24px;
      }
    }
  }

  &.hero-no-image {
    .title {
      color: #243128;
    }

    .text {
      color: #737882;
    }
  }

  .hero-icon {
    max-width: 120px;
    margin-bottom: 20px;
  }

  .page-template-template-referral & {
    &.hero-element-default {
      text-align: left;

      @media(min-width: $screen-md-min) {
        min-height: 500px;
      }

      @media(min-width: 1600px) {
        min-height: 870px;
      }
    }

    .hero-icon {
      width: 57px;
      height: auto;
      margin-left: 0;
      margin-right: 0;
    }

    .title {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 0;

      @media(min-width: $screen-sm-min) {
        font-size: 30px;
        margin-bottom: 20px;
      }

      @media(min-width: $screen-md-min) {
        font-size: 50px;
        padding-right: 50px;
      }

      @media(min-width: $screen-lg-min) {
        line-height: 0.95;
        font-size: 70px;
        letter-spacing: -2px;
        padding-right: 60px;
      }
    }

    .text {
      font-size: 16px;

      @media(min-width: $screen-sm-min) {
        font-size: 24px;
      }
    }
  }
}