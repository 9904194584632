/* This stylesheet generated by Transfonter (https://transfonter.org) on September 15, 2017 6:28 AM */

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/gf_fonts/ProximaNova-Light.eot');
  src: url('../fonts/gf_fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/ProximaNova-Light.woff2') format('woff2'),
  url('../fonts/gf_fonts/ProximaNova-Light.woff') format('woff'),
  url('../fonts/gf_fonts/ProximaNova-Light.ttf') format('truetype'),
  url('../fonts/gf_fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/gf_fonts/ProximaNova-Regular.eot');
  src: url('../fonts/gf_fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/gf_fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/gf_fonts/ProximaNova-Regular.ttf') format('truetype'),
  url('../fonts/gf_fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/gf_fonts/ProximaNova-LightIt.eot');
  src: url('../fonts/gf_fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/ProximaNova-LightIt.woff2') format('woff2'),
  url('../fonts/gf_fonts/ProximaNova-LightIt.woff') format('woff'),
  url('../fonts/gf_fonts/ProximaNova-LightIt.ttf') format('truetype'),
  url('../fonts/gf_fonts/ProximaNova-LightIt.svg#ProximaNova-LightIt') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/gf_fonts/ProximaNova-RegularIt.eot');
  src: url('../fonts/gf_fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/ProximaNova-RegularIt.woff2') format('woff2'),
  url('../fonts/gf_fonts/ProximaNova-RegularIt.woff') format('woff'),
  url('../fonts/gf_fonts/ProximaNova-RegularIt.ttf') format('truetype'),
  url('../fonts/gf_fonts/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gf_fonts/Gilroy-ExtraBold.eot');
  src: url('../fonts/gf_fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/Gilroy-ExtraBold.woff2') format('woff2'),
  url('../fonts/gf_fonts/Gilroy-ExtraBold.woff') format('woff'),
  url('../fonts/gf_fonts/Gilroy-ExtraBold.ttf') format('truetype'),
  url('../fonts/gf_fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GoodDog';
  src: url('../fonts/gf_fonts/GoodDog.eot');
  src: url('../fonts/gf_fonts/GoodDog.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gf_fonts/GoodDog.woff2') format('woff2'),
  url('../fonts/gf_fonts/GoodDog.woff') format('woff'),
  url('../fonts/gf_fonts/GoodDog.ttf') format('truetype'),
  url('../fonts/gf_fonts/GoodDog.svg#GoodDog') format('svg');
  font-weight: normal;
  font-style: normal;
}
