.menu-element {
  background: #F6F6F6;
  padding: 30px 0;

  @media(min-width: $screen-md-min) {
    padding: 50px 0;
  }

  .title {
    color: #393939;
    font-size: 20px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 54px;
      letter-spacing: -1.38px;
      margin-bottom: 30px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu {
    a {
      @include text-style('noval');
      color: #243128;
      display: block;
      background: #fff;
      padding: 5px;
      margin: 0 10px;
      transition: background 0.2s linear;
      position: relative;

      @media(min-width: $screen-sm-min) {
        font-size: 20px;
      }

      @media(min-width: $screen-md-min) {
        font-size: 25px;
        margin: 0 20px;
      }

      &:focus,
      &:hover {
        text-decoration: none;
        background: linear-gradient(to right, #f8f8f8 0%, #f8f8f8 40%, #ffffff 100%);
      }

      &::after {
        content: "\e258";
        font-family: "Glyphicons Halflings";
        line-height: 1;
        font-size: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 10px;

        @media(min-width: $screen-md-min) {
          font-size: 14px;
          height: 14px;
        }
      }
    }

    > li {
      background: #fff;
      margin: 3px 0;
      box-shadow: 0 0 20px 0 rgba(76, 77, 78, 0.09);
      border-radius: 4px;
      padding: 5px 0;

      > a {
      }

      &.open {
        > a {
          &::after {
            content: "\e259";
          }
        }

        .sub-menu {
          display: block;
        }
      }
    }

    .sub {
      font-size: 14px;
    }
  }

  .sub-menu {
    display: none;

    a {
      margin-left: 20px;
      font-size: 16px;
      color: #737882;

      @media(min-width: $screen-md-min) {
        margin-left: 30px;
        font-size: 20px;
      }

      &::after {
        margin-top: 6px;
      }
    }
  }
}