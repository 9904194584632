.half-box {
  background: #fff;
  background-size: cover;

  @media(min-width: $screen-lg-min) {
    min-height: 600px;
    display: flex;
    align-items: center;
  }

  .box-item {

  }

  .link-item {
    font-size: 15px;
    text-decoration: underline;
    color: #243128;

    @media(min-width: $screen-sm-min) {
      font-size: 18px;
    }

    span {
      position: relative;
      top: -1px;
    }
  }

  .title {
    color: #393939;
    font-size: 20px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 28px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 38px;
    }
  }

  .text {
    color: #737882;
    font-size: 16px;

    @media(min-width: $screen-sm-min) {
      font-size: 20px;
    }
  }

  .box-items {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  .box-item {
    width: 100%;
    flex-grow: 1;

    @media(min-width: $screen-md-min) {
      width: 50%;
    }
  }

  .text-box {
    padding: 40px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 200;

    @media(min-width: $screen-sm-min) {
      padding: 15px 15px 15px 60px;
    }

    @media(min-width: $screen-md-min) {
      padding: 15px 15px 15px 100px;
      font-size: 20px;

      .text {
        margin-bottom: 20px;
      }
    }

    @media(min-width: $screen-lg-min) {
      padding: 15px 15px 15px 140px;

      .text {
        padding-right: 250px;
        margin-bottom: 30px;
      }
    }
  }

  .visual-box {
    min-height: 300px;
    position: relative;
    display: block;
    background-size: cover;
    height: 100%;

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .visual-element {
      position: absolute;
      bottom: 0;
      right: 0;

      @media(min-width: $screen-md-min) {
        bottom: 3%;

        &.visual-element-left {
          right: -15%;
        }

        &.visual-element-right {
          left: 0%;
        }
      }
    }

    .sizer-image {
      opacity: 0.001;
    }
  }

  &.half-box-style-green {
    background: url(../images/green-bg-gf.png) center center no-repeat;
    background-size: cover;

    .link-item {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      width: auto;
      padding: 5px 8px;
      text-align: center;
      max-width: 200px;
    }

    .title {
      color: #fff;

      @media(min-width: $screen-lg-min) {
        font-size: 70px;
        letter-spacing: -2px;
        line-height: 65px;
      }
    }

    .text {
      color: #fff;
    }

    .visual-element {
      @media(min-width: $screen-lg-min) {
        &.visual-element-right {
          left: -11%;
          top: 0%;
          bottom: auto;
        }
      }
    }
  }
}