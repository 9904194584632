.splash-element {
  background-position: center 33%;
  background-size: cover;
  color: #fff;

  padding-top: 15px;
  padding-bottom: 15px;

  @media(min-width: $screen-sm-min) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media(min-width: $screen-md-min) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title {
    color: #fff;
    font-size: 25px;
    margin-bottom: 10px;
    @include text-style('gilroy');

    @media(min-width: $screen-sm-min) {
      font-size: 30px;
    }

    @media(min-width: $screen-md-min) {
      font-size: 43px;
    }
  }

  .text {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 200;

    @media(min-width: $screen-sm-min) {
      font-size: 18px;
      margin-bottom: 30px;
    }

    @media(min-width: $screen-md-min) {
      margin-bottom: 50px;
    }
  }
}